import React, { Component } from "react";
// import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import SEO from "../../seo";
import sliderImages from "./slider.data";

const details = {
  title: "Delhi Public School, Faridabad",
  address: "Sector 98, Faridabad",
  client: "Haryana Hansraj Education Society",
  area: "2,75,000 Sq. Ft.",
  project: "Delhi Public School, Greater Faridabad (Winning Design Entry)",
  category: "Institutions | Interiors | School",
  status: "Phase 1 Completed",
  backlink: "/projects/institutions/",
};

export default class Projectdpsfaridabad extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Delhi Public School, Faridabad"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the award-winning design and layout of DPS Faridabad that is currently under development. "
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
