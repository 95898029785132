const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/1.png",
    text: "Architecture 1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/2.png",
    text: "Architecture 2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/3.png",
    text: "Architecture 3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/4.png",
    text: "Architecture 4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/5.png",
    text: "Architecture 5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/6.png",
    text: "Architecture 6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/7.png",
    text: "Architecture 7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/8.png",
    text: "Architecture 8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/9.png",
    text: "Architecture 9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/10.png",
    text: "Architecture 10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/11.png",
    text: "Architecture 11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/12.png",
    text: "Architecture 12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/13.png",
    text: "Architecture 13",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/interiors/1.png",
    text: "Interiors 1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/interiors/2.png",
    text: "Interiors 2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/interiors/3.png",
    text: "Interiors 3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/interiors/4.png",
    text: "Interiors 4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsfaridabad/interiors/5.png",
    text: "Interiors 5",
  },
];

export default data;
